import { Config } from '#app/config'
import {
  UsagePageSearchParamsSchema,
  createUsagePageQueryVariables,
} from '#app/pages/usage/usage'
import node, {
  type personalUsagePageQuery,
} from '#gen/relay/personalUsagePageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute, notFound } from '@tanstack/react-router'
import { parse } from 'valibot'

export const Route = createFileRoute('/_dashboard/_personal/settings/usage')({
  staticData: { title: 'Usage' },
  validateSearch(search) {
    return parse(UsagePageSearchParamsSchema, search)
  },
  loaderDeps(opts) {
    return {
      search: opts.search,
    }
  },
  async loader({ context, deps }) {
    if (Config.IS_ENTERPRISE_BUILD) throw notFound()

    return context.preload<personalUsagePageQuery>(
      node,
      createUsagePageQueryVariables(deps.search),
    )
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/usage/personal-usage-page'),
    'PersonalUsagePage',
  ),
})
