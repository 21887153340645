import { Config } from '#app/config'
import { captureException } from '@sentry/react'
import {
  Environment,
  Network,
  ROOT_TYPE,
  RecordSource,
  Store,
} from 'relay-runtime'

export function createRelayEnvironment(getToken: () => Promise<string | null>) {
  const network = Network.create(async (request, variables) => {
    const token = await getToken()

    if (!token) throw new Error('Missing authentication token')

    const headers = new Headers()
    headers.append('Authorization', `Bearer ${token}`)
    headers.append('Content-Type', 'application/json')
    headers.append(
      'Accept',
      'application/graphql-response+json; charset=utf-8, application/json; charset=utf-8',
    )

    if (Config.CLIENT_NAME)
      headers.append('x-grafbase-client-name', Config.CLIENT_NAME)
    if (Config.CLIENT_VERSION)
      headers.append('x-grafbase-client-version', Config.CLIENT_VERSION)

    const featureFlags = localStorage.getItem('feature-flags')
    if (featureFlags) headers.append('X-Feature-Flags', featureFlags)

    try {
      const resp = await fetch(Config.GRAFBASE_GRAPHQL_API_URL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          query: request.text,
          variables,
        }),
        signal:
          request.operationKind === 'query'
            ? AbortSignal.timeout(15_000)
            : undefined,
      })

      if (!resp.ok) {
        const error = new Error(
          `Network error ${resp.status} ${resp.statusText}`,
        )
        captureException(error)
        throw error
      }

      const json = await resp.json()

      // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
      // property of the response. If any exceptions occurred when processing the request,
      // throw an error to indicate to the developer what went wrong.
      if (Array.isArray(json.errors)) {
        captureException(json.errors)

        console.error(
          `Error fetching GraphQL query '${
            request.name
          }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
            json.errors,
          )}`,
        )
      }

      return json
    } catch (error) {
      if (error instanceof Error && error.name === 'TimeoutError') {
        throw new Error('API request timed out')
      }

      throw error
    }
  })

  const store = new Store(RecordSource.create())

  return new Environment({
    store,
    network,
    missingFieldHandlers: [
      {
        kind: 'linked',
        handle(field, record, args) {
          if (record != null && record.getType() === ROOT_TYPE) {
            if (
              field.name === 'node' &&
              Object.prototype.hasOwnProperty.call(args, 'id')
            ) {
              return args.id
            }
          }

          return undefined
        },
      },
    ],
  })
}
