/**
 * @generated SignedSource<<2edb62714b5817730a04d33f8e7819e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrafbasePlan = "ENTERPRISE" | "HOBBY" | "PRO" | "TRIAL" | "%future added value";
export type graphDomainsPageQuery$variables = {
  account: string;
  graph: string;
};
export type graphDomainsPageQuery$data = {
  readonly account: {
    readonly plan: GrafbasePlan;
    readonly slug: string;
  } | null | undefined;
  readonly graph: {
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"assignDomainFormFragment" | "verifyDomainSectionFragment">;
  } | null | undefined;
};
export type graphDomainsPageQuery = {
  response: graphDomainsPageQuery$data;
  variables: graphDomainsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "graphDomainsPageQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "graph",
        "args": (v4/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "assignDomainFormFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "verifyDomainSectionFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "graphDomainsPageQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "graph",
        "args": (v4/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BranchConnection",
            "kind": "LinkedField",
            "name": "branches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BranchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Branch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Branch",
            "kind": "LinkedField",
            "name": "productionBranch",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomDomain",
            "kind": "LinkedField",
            "name": "customDomains",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "branch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hostnameVerificationStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hostnameVerificationTxtName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hostnameVerificationTxtValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sslVerificationCnameName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sslVerificationCnameValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sslVerificationStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83a8efd5e5967bb44da712f4efa1f637",
    "id": null,
    "metadata": {},
    "name": "graphDomainsPageQuery",
    "operationKind": "query",
    "text": "query graphDomainsPageQuery(\n  $account: String!\n  $graph: String!\n) {\n  account: accountBySlug(slug: $account) {\n    __typename\n    plan\n    slug\n    id\n  }\n  graph: graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    slug\n    ...assignDomainFormFragment\n    ...verifyDomainSectionFragment\n    id\n  }\n}\n\nfragment assignDomainFormFragment on Graph {\n  id\n  slug\n  branches {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  productionBranch {\n    id\n    name\n  }\n  customDomains {\n    name\n    branch\n  }\n}\n\nfragment deleteCustomDomainButtonFragment on Graph {\n  id\n  customDomains {\n    name\n  }\n}\n\nfragment verifyDomainSectionFragment on Graph {\n  slug\n  customDomains {\n    name\n    hostnameVerificationStatus\n    hostnameVerificationTxtName\n    hostnameVerificationTxtValue\n    sslVerificationCnameName\n    sslVerificationCnameValue\n    sslVerificationStatus\n  }\n  ...deleteCustomDomainButtonFragment\n  id\n}\n"
  }
};
})();

(node as any).hash = "6d1acfa0e5a51a889ddc74b3a9fe3e52";

export default node;
