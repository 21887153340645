/**
 * @generated SignedSource<<f9ecbed79c584f4a6f4fb1563f60cf24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrafbasePlan = "ENTERPRISE" | "HOBBY" | "PRO" | "TRIAL" | "%future added value";
export type billingPageQuery$variables = {
  account: string;
};
export type billingPageQuery$data = {
  readonly accountBySlug: {
    readonly plan: GrafbasePlan;
    readonly " $fragmentSpreads": FragmentRefs<"billingAddressFragment" | "invoiceEmailFragment" | "invoiceNameFragment" | "paymentMethodFragment" | "planFragment" | "taxIdFragment">;
  };
};
export type billingPageQuery = {
  response: billingPageQuery$data;
  variables: billingPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "used",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "billingPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "planFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "paymentMethodFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "billingAddressFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "invoiceNameFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "invoiceEmailFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "taxIdFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "accountBySlug"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "billingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "limitCycleChoice": "CURRENT"
                    }
                  }
                ],
                "concreteType": null,
                "kind": "LinkedField",
                "name": "resourcesUsageDashboard",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountResourcesStatus",
                        "kind": "LinkedField",
                        "name": "status",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ResourceStatus",
                            "kind": "LinkedField",
                            "name": "requestCount",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ResourceStatus",
                            "kind": "LinkedField",
                            "name": "execUnits",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ResourceStatus",
                            "kind": "LinkedField",
                            "name": "buildDuration",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "AccountResourcesUsageDashboardSuccess",
                    "abstractKey": null
                  }
                ],
                "storageKey": "resourcesUsageDashboard(filter:{\"limitCycleChoice\":\"CURRENT\"})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrbCustomer",
                "kind": "LinkedField",
                "name": "orbCustomer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrbPaginatedSubscriptions",
                    "kind": "LinkedField",
                    "name": "subscriptions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrbSubscription",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currentBillingPeriodStartDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currentBillingPeriodEndDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrbUpcomingInvoice",
                            "kind": "LinkedField",
                            "name": "upcomingInvoice",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "subtotal",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrbUpcomingInvoiceLineItem",
                                "kind": "LinkedField",
                                "name": "lineItems",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "quantity",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "amount",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrbPrice",
                                    "kind": "LinkedField",
                                    "name": "price",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "OrbPriceUnitConfig",
                                        "kind": "LinkedField",
                                        "name": "unitConfig",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "unitAmount",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "OrbPricePackageConfig",
                                        "kind": "LinkedField",
                                        "name": "packageConfig",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "packageAmount",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrbBillingAddress",
                    "kind": "LinkedField",
                    "name": "billingAddress",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "line1",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "line2",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postalCode",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrbTaxId",
                    "kind": "LinkedField",
                    "name": "taxId",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StripeCustomer",
                "kind": "LinkedField",
                "name": "stripeCustomer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StripePaginatedPaymentMethods",
                    "kind": "LinkedField",
                    "name": "paymentMethods",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StripePaymentMethod",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "StripeCard",
                            "kind": "LinkedField",
                            "name": "card",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "brand",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "expMonth",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "expYear",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "funding",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "last4",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14bbf6a533339aa3b7f32b235ac9517d",
    "id": null,
    "metadata": {},
    "name": "billingPageQuery",
    "operationKind": "query",
    "text": "query billingPageQuery(\n  $account: String!\n) {\n  accountBySlug(slug: $account) {\n    __typename\n    plan\n    ...planFragment\n    ...paymentMethodFragment\n    ...billingAddressFragment\n    ...invoiceNameFragment\n    ...invoiceEmailFragment\n    ...taxIdFragment\n    id\n  }\n}\n\nfragment billingAddressFragment on Organization {\n  id\n  orbCustomer {\n    billingAddress {\n      line1\n      line2\n      city\n      postalCode\n      country\n    }\n  }\n}\n\nfragment invoiceEmailFragment on Organization {\n  id\n  orbCustomer {\n    email\n  }\n}\n\nfragment invoiceNameFragment on Organization {\n  id\n  name\n  orbCustomer {\n    name\n  }\n}\n\nfragment paymentMethodFragment on Organization {\n  id\n  stripeCustomer {\n    paymentMethods {\n      data {\n        id\n        card {\n          brand\n          expMonth\n          expYear\n          funding\n          last4\n        }\n      }\n    }\n  }\n}\n\nfragment planFragment on Organization {\n  resourcesUsageDashboard(filter: {limitCycleChoice: CURRENT}) {\n    __typename\n    ... on AccountResourcesUsageDashboardSuccess {\n      status {\n        requestCount {\n          used\n        }\n        execUnits {\n          used\n        }\n        buildDuration {\n          used\n        }\n      }\n    }\n  }\n  orbCustomer {\n    subscriptions {\n      data {\n        currentBillingPeriodStartDate\n        currentBillingPeriodEndDate\n        upcomingInvoice {\n          currency\n          subtotal\n          lineItems {\n            name\n            quantity\n            amount\n            price {\n              currency\n              unitConfig {\n                unitAmount\n              }\n              packageConfig {\n                packageAmount\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment taxIdFragment on Organization {\n  id\n  orbCustomer {\n    taxId {\n      type\n      value\n      country\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbaaf1bfa3de9b0915b9681da6b47906";

export default node;
