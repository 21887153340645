/**
 * @generated SignedSource<<7cff6dd12aa06544fa9f2acad98b9867>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type proposalDiffPageQuery$variables = {
  proposalId: string;
};
export type proposalDiffPageQuery$data = {
  readonly schemaProposal: {
    readonly author: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly subgraphs: ReadonlyArray<{
      readonly diffSnippets: ReadonlyArray<{
        readonly addedLines: ReadonlyArray<number>;
        readonly originalSchemaSnippet: string;
        readonly originalSchemaSnippetFirstLine: number;
        readonly proposedSchemaSnippet: string;
        readonly proposedSchemaSnippetFirstLine: number;
        readonly removedLines: ReadonlyArray<number>;
      }>;
      readonly name: string;
    }>;
  };
  readonly viewer: {
    readonly __typename: "User";
    readonly id: string;
  };
};
export type proposalDiffPageQuery = {
  response: proposalDiffPageQuery$data;
  variables: proposalDiffPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "proposalId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "proposalId"
    }
  ],
  "concreteType": "SchemaProposal",
  "kind": "LinkedField",
  "name": "schemaProposal",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SchemaProposalSubgraph",
      "kind": "LinkedField",
      "name": "subgraphs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DiffSnippet",
          "kind": "LinkedField",
          "name": "diffSnippets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "originalSchemaSnippet",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "originalSchemaSnippetFirstLine",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "proposedSchemaSnippet",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "proposedSchemaSnippetFirstLine",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "addedLines",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "removedLines",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalDiffPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v2/*: any*/),
        "action": "THROW",
        "path": "viewer"
      },
      {
        "kind": "RequiredField",
        "field": (v3/*: any*/),
        "action": "THROW",
        "path": "schemaProposal"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "proposalDiffPageQuery",
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "381f2135eb7f7ed689e30782c30a6efe",
    "id": null,
    "metadata": {},
    "name": "proposalDiffPageQuery",
    "operationKind": "query",
    "text": "query proposalDiffPageQuery(\n  $proposalId: ID!\n) {\n  viewer {\n    __typename\n    id\n  }\n  schemaProposal(id: $proposalId) {\n    id\n    author {\n      id\n    }\n    subgraphs {\n      name\n      diffSnippets {\n        originalSchemaSnippet\n        originalSchemaSnippetFirstLine\n        proposedSchemaSnippet\n        proposedSchemaSnippetFirstLine\n        addedLines\n        removedLines\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4648254b8835b00b25bcfc207efb855f";

export default node;
